"use client"
  import __i18nConfig from '@next-translate-root/i18n'
  import AppDirI18nProvider from 'next-translate/AppDirI18nProvider'
  import { useSearchParams as __useSearchParams, useParams as __useParams } from 'next/navigation'
  import { use as __use, Suspense as __Suspense } from 'react'
  import __loadNamespaces from 'next-translate/loadNamespaces'

  ; // Error components must be Client Components
import React from 'react';
function Error({ error, reset }: {
    error: Error & {
        digest?: string;
    };
    reset: () => void;
}) {
    return (<div>
      <h2>Something went wrong!</h2>
      <button onClick={
        // Attempt to recover by trying to re-render the segment
        () => reset()}>
        Try again
      </button>
    </div>);
}


  export default function __Next_Translate_new__19619025509__(props) {
    const searchParams = __useSearchParams()
    const params = __useParams()
    const detectedLang = params.lang ?? searchParams.get('lang')

    if (detectedLang === 'favicon.ico') return <Error {...props} />

    if (globalThis.__NEXT_TRANSLATE__ && !detectedLang) return <Error {...props} />

    const lang = detectedLang ?? __i18nConfig.defaultLocale
    const config = {
      ...__i18nConfig,
      locale: lang,
      loaderName: 'client /error',
      pathname: '/',
    }

    return (
      <__Suspense fallback={null}>
        <__Next_Translate__child__19619025509__ 
          {...props} 
          config={config} 
          promise={__loadNamespaces({ ...config, loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)), })}
         />
      </__Suspense>
    )
  }

  function __Next_Translate__child__19619025509__({ promise, config, ...props }) {
    const { __lang, __namespaces } = __use(promise);
    const oldNamespaces = globalThis.__NEXT_TRANSLATE__?.namespaces ?? {};
    globalThis.__NEXT_TRANSLATE__ = { lang: __lang, namespaces: { ...oldNamespaces, ...__namespaces }, config };
    return <Error {...props} />;
  }
